import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  constructor(private session:AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    //valido si se está loguenndo
    const esLogin:boolean=req.url.trim().includes("/login");

    //obtengo el header de la petición que va en camino
    var header = req.headers;


    //si no está haciendo una petición al login entonces la intercepto (para loguearse no necesita token)
    if(!esLogin){
      //le inserto el token en Authorization en el header
      header = header.set("Authorization", this.session.authToken);
      
    }

    

    //una req que ya se manipula no se puede volver a llamar, por lo que hay que hacer un clon
    const clon = req.clone({
      headers:header
    })


    //envio el clon de la request
    return next.handle(clon).pipe( //esto envia la petición

      //en caso de que retorne un error tambien lo proceso con una función para retornar un string más bonito
      catchError(err => {
        return this.trabajarError(err,this.session);
        
      
      }),
    );


    
    //throw new Error('Method not implemented.');
  }

  trabajarError(error:HttpErrorResponse,session:AuthService){

   
    if(error.status == 401) //(Unauthorized)
    {
      //cierra la sesion y redirecciona al login
      session.fullLogout()
    }

    if(error.status == 400){ //(Bad Request)

      let mensaje='';

      if(error.error.mensaje){
        mensaje='<ul>';
        if(error.error.mensaje.constructor.name=='Array'){
            for (let i = 0; i < error.error.mensaje.length; i++) {
              mensaje+='<li>'+error.error.mensaje[i]+'</li>';
            }
        }else{
          mensaje+=error.error.mensaje
        }
        
        mensaje+='</ul>';

        return throwError(mensaje)
      
      //Esto es para los casos de los serializer
      }else if(error.error.constructor.name == "Object"){

        let claves = Object.keys(error.error); // claves = ["nombre", "color", "macho", "edad"]
        for(let i=0; i< claves.length; i++){
          let clave = claves[i];
          for (let j = 0; j < error.error[clave].length; j++) {
      
            mensaje+="<b>"+clave+"</b> : "+error.error[clave][j]+"<br>"
          }
          
        }
        return throwError(mensaje)
      }
    }

    return throwError('Algo no anda bien, intente nuevamente. Si el problema persiste comuniquese con sistemas@academia.cl\n(ERROR '+error.status+')')
  }
}
