import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';




@Component({
  selector: 'app-no-valido',
  templateUrl: './no-valido.component.html',
})
export class NoValidoComponent implements OnInit {

  constructor() { }


  errores :string[] = [""];

  @Input() index!:number;
  @Input() formulario!:any
  @Input() campo!:string

  ngOnInit(): void {
  }

  noValido():boolean{
    this.errores=[];

    if(this.formulario.constructor.name=="FormGroup"){
      return this.ValidadorFormGroup( this.formulario );
    }
    else if(this.formulario.constructor.name=="FormArray"){
      return this.ValidadorFormArray( this.formulario );
    }
    
    return false;

  }

  ValidadorFormArray(formulario:FormArray):boolean{
    
    //rescato el FormGroup que está dentro de este indice
    //Ojo: hay que ver los casos en que no sea un FormGroup si no un FormControl
    var formo = formulario.controls[this.index] as FormGroup;
    return this.ValidadorFormGroup(formo)

  }

  ValidadorFormGroup(formulario:FormGroup):boolean{
    
    if (formulario.controls[this.campo].invalid 
      && formulario.controls[this.campo].touched
    ){

      if(formulario.controls[this.campo].errors?.alert){
        this.errores.push( 
          formulario.controls[this.campo].errors?.alert
        );
      }
      
      if(  formulario.controls[this.campo].errors?.required){
        this.errores.push( 
          "Este campo es obligatorio"
        );
      }

      if(  formulario.controls[this.campo].errors?.minlength){
        this.errores.push( 
          "El largo minimo permitido es de "+formulario.controls[this.campo].errors?.minlength.requiredLength
        );
      }

      if(  formulario.controls[this.campo].errors?.maxlength){
        this.errores.push( 
          "El largo máximo permitido es de "+formulario.controls[this.campo].errors?.maxlength.requiredLength+", actualmente tiene "+formulario.controls[this.campo].errors?.maxlength.actualLength+" caracteres"
        );
      }

      if(  formulario.controls[this.campo].errors?.min){
        this.errores.push( 
          "El minimo permitido es de "+formulario.controls[this.campo].errors?.min.min
        );
      }

      if(  formulario.controls[this.campo].errors?.max){
        this.errores.push( 
          "El maximo permitido es de "+formulario.controls[this.campo].errors?.max.max
        );
      }

      return true;

    }

    return false;
  }



}
