<nav class="navbar navbar-expand-md fixed-top am-top-header">
    <div class="container-fluid">
      <div class="am-navbar-header">
        <div class="page-title"><span>Unify</span></div><a class="am-toggle-left-sidebar navbar-toggle collapsed" href="#"><span class="icon-bar"><span></span><span></span><span></span></span></a><a class="navbar-brand" href="index.html"></a>
      </div>
      <button class="navbar-toggler hidden-md-up collapsed" data-toggle="collapse" data-target="#am-navbar-collapse"><span class="icon s7-angle-down"></span></button>
      <div class="collapse navbar-collapse" id="am-navbar-collapse">
        <ul class="nav navbar-nav am-user-nav">
          <li class="dropdown"><a class="dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
            
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
            
            
            <span class="user-name">{{auth?.username|uppercase}}</span><span class="angle-down s7-angle-down"></span></a>
            <ul class="dropdown-menu" role="menu">
              <!--<li><a href="#"> <span class="icon s7-user"></span>Mi perfil</a></li>-->
              <!--<li><a href="#"> <span class="icon s7-config"></span>Settings</a></li>-->
              <li (click)="logout()"><a> <span class="icon s7-power"></span>Salir</a></li>
            </ul>
          </li>
        </ul>
        <ul class="nav navbar-nav am-top-menu">
          <li><a routerLink="/">Inicio</a></li>

          <li class="dropdown" *ngIf="roles.length>0">
            <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">{{rol.nombre|primeraMayuscula}} <span class="angle-down s7-angle-down"></span></a>
            <ul class="dropdown-menu" role="menu">
              <li *ngFor="let rol of roles" class="active" (click)="selectRol(rol)"><a >{{rol.nombre|primeraMayuscula}}</a></li>
            </ul>
          </li>
          

        </ul>
        <ul class="nav navbar-nav am-icons-nav">
          <li class="dropdown">
            <a class="dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
              <span class="icon s7-bell"></span>
              <span *ngIf="cant_notificaciones" class="indicator"></span>
            </a>

            <ul class="dropdown-menu am-notifications">
              <li>
                <app-notificaciones (cant_notificaciones)="setSantidad($event)" ></app-notificaciones>
              </li>
            </ul>
          </li>
        </ul>

      </div><a class="am-toggle-right-sidebar" href="#"><span class="icon s7-menu2"></span></a>
      
    </div>
  </nav>
