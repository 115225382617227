import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator/paginator.component';
import { NoValidoComponent } from './no-valido/no-valido.component';
import { PrimeraMayusculaPipe } from './pipes/primera-mayuscula.pipe';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { UnifyDatePipe } from './pipes/unify-date.pipe';
import { CortaCadenaPipe } from 'src/app/env/components/pipes/corta-cadena.pipe';


@NgModule({
  declarations: [
    PaginatorComponent, //Este componente nos permite hacer paginaciones
    NoValidoComponent,  //Este componente nos permite mostrar un mensaje cuando el formulario no es válido
    DropdownComponent,
    CortaCadenaPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports:[
    PaginatorComponent,
    NoValidoComponent,
    DropdownComponent,
    CortaCadenaPipe
  ]
})
export class UnifyComponentsModule { }
