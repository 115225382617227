import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';




@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit {

  constructor() { }
  search=""
  registersFiler:any[]=[]

  @Output() change:EventEmitter<any> = new EventEmitter();
  @Input() value!:string
  @Input() separator:string=" ";
  @Input() optionLabels:string[]=[];
  @Input() placeholder:string="";
  @Input() registers:any[]=[];
  @Input() form:any|null;

  @Input() filters:any[]=[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.optionLabels && this.filters.length==0) {
      this.filters=[...this.optionLabels]
    }
    if (changes.registers) {
      this.registersFiler=[...this.registers]
    }
  }

  ngOnInit(): void {
  }

  
  keyUpSearch(){
    this.registersFiler=[]

    if( this.search.replace(/[ ]/g,'').length == 0 ){this.registersFiler=[...this.registers];this.setValue(null);return;}

    
    for (let i = 0; i < this.registers.length; i++) {
      let add=false
      for (let j = 0; j < this.filters.length; j++) {
        if(this.registers[i][this.filters[j]].toLowerCase().includes(this.search.toLowerCase()) )add=true
      }
      if(add)this.registersFiler.push(this.registers[i])
      
    }
  }

  setValue(element:any){
    

    if(!this.form)return;
    let form = this.form as FormControl

    if(element==null){
      form.setValue('')
    }

    form.setValue(element[this.value])
  }

  select(element:any){
    this.search=""
    for (let i = 0; i < this.filters.length; i++) {
      this.search+=i==0?element[this.filters[i]]:this.separator+element[this.filters[i]]
    }

    this.setValue(element);

    this.change.emit(element);

  }



}
