import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './pages/index/index.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { TemplateModule } from './components/template.module';
import { ConfigRoutingModule } from 'src/app/env/config-routing.module';
import { PrimengModule } from '../../primeng/primeng.module';
import { UnifyComponentsModule } from '../../components/unify-components.module';


@NgModule({
  declarations: [
    IndexComponent,
    HomeComponent
  ],
  imports: [
    RouterModule,

    CommonModule,
    ConfigRoutingModule,

    PrimengModule,

    TemplateModule,

    UnifyComponentsModule
  ],
  exports:[
    HomeComponent
  ]
})
export class HomeModule { }
