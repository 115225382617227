import {   Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { App } from 'src/app/env/app/interfaces/app.interface';
import { Rol } from 'src/app/env/app/interfaces/rol.interface';
import { MenuIconService } from 'src/app/env/menu-icon.service';

@Component({
  selector: 'app-sider-bar',
  templateUrl: './sider-bar.component.html',
  styles:[
    `
    li{
      cursor:pointer
    }
    `
  ]
})
export class SiderBarComponent {

  constructor(
    private icon:MenuIconService,
    private router:Router,
    ) {
   
  }




  //este evento retorna la app seleccionada
  @Output() appSelect:EventEmitter<App[]> = new EventEmitter();

  getPathActual(){
    return this.router.url.substring(this.router.url.length-1)!="/"?this.router.url+"/":this.router.url.substring(this.router.url.length-1)
  }

  getPathPrincipalActual(){
    let path = this.router.url
    let auxpath = path.split("/")
    return "/"+auxpath[1]+"/"
  }


  @Input() rol : Rol = {
    apps:[],
    descripcion:"",
    nombre:"",
    token:"",
    super_user:false
  }

  @Input() apps : App[] = []

  

  

  getIcon(codigoApp:string){

    return "s7-monitor";
  }

  

  select(app:App,appHija:App){
    this.appSelect.emit([app,appHija])
    window.scroll(0,0)
  }

  selectPadre(app:App){
    if(app.hijos.length>0)return
    this.appSelect.emit([app])
    
  }


  getIcono(path:string):String{
    return this.icon.getIcon(path);
  }

  getNombre(nombre:string){
    let aux_nombre=nombre.split(" ")
    nombre="";
    for (let i = 0; i < aux_nombre.length; i++) {
      if(nombre.length==0){
        nombre=((aux_nombre[i]+"").trim()).length>10?(aux_nombre[i]+"").trim().substring(0,9)+"...":(aux_nombre[i]+"").trim();
      }else{
        nombre+=" "+(((aux_nombre[i]+"").trim()).length>10?(aux_nombre[i]+"").trim().substring(0,8)+"...":(aux_nombre[i]+"").trim());
      }
      
    }

    return nombre;
  }


}
