<div class="am-wrapper">
    
  <app-nav-bar (rolSeleccionado)="cargarAppsByRol($event)"></app-nav-bar>

  <app-sider-bar (appSelect)="asignApp($event)" [rol]="rol" [apps]="apps" ></app-sider-bar> 

  

    <div class="am-content">

      <p-breadcrumb [model]="items"></p-breadcrumb>
      
      <!-- <div class="page-head"> -->
        <!-- <h2>{{appNivel2?appNivel1?.nombre+' - ':appNivel1?.nombre }} {{appNivel2?.nombre}}</h2> -->
        <!--
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Layouts</a></li>
          <li class="breadcrumb-item active">Sticky Footer</li>
        </ol>
        -->
      <!-- </div> -->
      <div class="tab-container ">
        <ul class="nav nav-tabs" role="tablist">
     
          <li class="nav-item " *ngIf="!appNivel1" >
            <a class="nav-link active" data-toggle="tab" role="tab"><!--Inicio--></a>
          </li>
          <!-- Apps de nivel 3-->
          <li *ngFor="let app of getApps(); let i = index" 
              class="nav-item " 
            >
            <a [class]="getClass(i,app)" 
            
                (click)="app.hijos.length==0?this.appNivel4=undefined:undefined"
                [routerLink]="app.accesible?[app.path]:[]"

                [ngClass]="{'dropdown-toggle': app.hijos.length>0?true:false}"  
    
                [attr.data-toggle]=" setDataToggle(app) " 
    
                role="tab">{{app.nombre | titlecase}}</a>
            
            <div class="dropdown-menu" *ngIf="app.hijos.length>0?true:false">
              <a class="dropdown-item" *ngFor="let appHija of app.hijos" (click)="appNivel4=appHija" [style]="appHija.token==appNivel4?.token?'background-color: #FAE09D;color:#000':''" [routerLink]="[appHija.path?appHija.path:'']" >{{appHija.nombre | titlecase}}</a>
            </div>
          </li>
        </ul>
      </div>
      

      <!-- <div class="main-content"> -->
      <div>
          <router-outlet></router-outlet>
      </div>
    </div>
    <app-nav-perfil></app-nav-perfil>
  </div>

  
  
