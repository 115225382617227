import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuIconService {

  constructor() { }

  //agrega un icono para el menú de aplicaciones o grupos
  //puedes buscar tu icono en  ui-icons.php del template amaretti
  //Ingrese el path de la app sin /
  getIcon(path:string):String{

    path = path.replace(/[/]/g,"").trim().toLowerCase()


    switch (path) {

      //usar el nombre de la aplicacion que está en config-routing.module.ts
      case 'nucleo': return 's7-monitor';
      case 'solicitudes': return 's7-paper-plane';
      case 'recursos': return 's7-download';
      case 'vinformulario' : return 's7-note2';
      case 'aforocovid' : return 's7-id';
      case 'consulta_renovante' : return 's7-next-2';
      case 'cuestionario' : return 's7-news-paper';
      case 'consulta_titulados' : return 's7-culture';
      case 'carta_cobranza':return 's7-credit';
      case 'crm':return 's7-users';
      case 'decreto_nombramiento':return 's7-ribbon';
      case 'mantenedor':return 's7-server';
      case 'educacion_continua':return 's7-study';
      case 'convenio':return 's7-note';
      case 'bolsa_trabajo':return 's7-radio';
      case 'boletas':return 's7-calculator';
      case 'admin_salas':return 's7-culture';
      case 'formulario_ase':return 's7-news-paper';
      case 'presupuesto':return 's7-door-lock';
      case 'reporteria':return 's7-graph2'


      default: return 's7-attention';
    }

  }

}
