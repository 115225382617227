import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Notificacion } from '../../../interfaces/notificaciones/notificacion.interface';
import { EnvAppService } from '../../services/env-app.service';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  //styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  constructor(
    private services:EnvAppService,
    public session:AuthService,
    private router:Router,
  ) { }

  @Output() cant_notificaciones:EventEmitter<number> = new EventEmitter();

  notificaciones:Notificacion[]=[]

  ngOnInit(): void {
    this.services.getNotificaciones().subscribe(
      result=>{
        //console.log(result)
        this.notificaciones=result
        this.cant_notificaciones.emit(this.notificaciones.length)
      }
    )
  }

  ir(notificacion:Notificacion){
    this.router.navigate(['cuestionario/'+notificacion.modelo_id]);
  }

}
