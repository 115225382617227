import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
              private session:AuthService,
              private router:Router,
            ){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean > | Promise<boolean > | boolean  {
    if(!this.session.isAuthenticated()){
      this.router.navigate(['/login'])
      //return false;
    }

    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean  {

    if(!this.session.isAuthenticated()){

      //this.router.navigate(['/login'])
      const reg = /[,]/g
      //this.router.navigate(["/login/"+(segments).toString().replace(reg,'/')])
      this.router.navigate(["/login",(segments).toString().replace(reg,'/')])
      //window.location.href = "/login/"+(segments).toString().replace(reg,'/')
      //return false;
    }

    return true;
  }
}
