


<div class="card">
  <div class="card-header d-flex justify-content-between">
      Bienvenido/a
  </div>
  <!-- <h5 class="card-title">Universidad Academia de Humanismo Cristiano - Unify</h5> -->
  <div class="card-body">
       <!--HOME FINANZAS y BECAS Y CREDITOS-->
      <!-- <div class="row" *ngIf="rolActual.nombre === 'FINANZAS' || rolActual.nombre === 'BECAS_CREDITOS'">
        <div class="col-12">
          <p-messages severity="warn" *ngIf="nuevo">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle" style="font-size: 20px;"></i>
                <div [innerHtml]="nuevoConvenio" style="font-size: 16px;" class="ml-2"></div>
            </ng-template>
          </p-messages>
        </div>
        <div class="col-sm-6">

        </div>
        <div class="col-sm-6">
      
        </div>
      </div> -->



      <!--ALERTAS DE CONVENIOS-->
      <div class="row" *ngIf="rolActual.nombre === 'VINCULO' || rolActual.nombre === 'FINANZAS' || rolActual.nombre === 'BECAS_CREDITOS'">
        <div class="col-12">
          <p-messages severity="warn" *ngIf="nuevo">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle" style="font-size: 20px;"></i>
                <div [innerHtml]="msgConvenioNuevo" style="font-size: 16px;" class="ml-2"></div>
            </ng-template>
          </p-messages>
          <p-messages severity="warn" *ngIf="renovar">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle" style="font-size: 20px;"></i>
                <div [innerHtml]="msgConvenioRenovar" style="font-size: 16px;" class="ml-2"></div>
            </ng-template>
          </p-messages>
          <p-messages severity="warn" *ngIf="vencer">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle" style="font-size: 20px;"></i>
                <div [innerHtml]="msgConvenioVencer" style="font-size: 16px;" class="ml-2"></div>
            </ng-template>
          </p-messages>
          <p-messages severity="warn" *ngIf="vencido">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle" style="font-size: 20px;"></i>
                <div [innerHtml]="msgConvenioVencido" style="font-size: 16px;" class="ml-2"></div>
            </ng-template>
          </p-messages>
          <p-messages severity="warn" *ngIf="cerrado">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle" style="font-size: 20px;"></i>
                <div [innerHtml]="msgConvenioCerrado" style="font-size: 16px;" class="ml-2"></div>
            </ng-template>
          </p-messages>
        </div>
        <div class="col-sm-6">
            <p-card header="NUEVO: Convenios" subheader="Consulta convenios en línea" [style]="{width: '360px'}" styleClass="p-card-shadow">
                <ng-template pTemplate="header">
                    <img alt="Gif animado que muestra la ubicacion en el menú" src="../../../../../assets/img/noticia.gif">
                </ng-template>
                <p>Ya está disponible la nueva herramienta de convenios donde puedes revisar toda la información en línea <i class="fa fa-envelope" aria-hidden="true"></i></p>
            </p-card>
      
        </div>
        <div class="col-sm-6">
      
        </div>
      </div>

  </div>
  <div class="card-footer text-muted text-center">
    Unify - Universidad Academia de Humanismo Cristiano
  </div>
</div>



<!-- <div class="row">
  <div class="col">
    <div class="widget widget-pie">
      <div class="widget-head"><span class="title">Bienvenido/a</span></div>
      <div class="row chart-container">
        Universidad Academia de Humanismo Cristiano
      </div>

      <div class="row" *ngIf="rolActual.nombre === 'FINANZAS'">
        <div class="col-12">
          <p-messages severity="warn">
            <ng-template pTemplate>
                <i class="pi pi-info-circle"></i>
                <div [innerHtml]="msgs" class="ml-2"></div>
            </ng-template>
        </p-messages>
        </div>
        <div class="col-sm-6">
            <p-card header="[NUEVO]: Convenios" subheader="Consulta convenios en línea" [style]="{width: '360px'}" styleClass="p-card-shadow">
                <ng-template pTemplate="header">
                    <img alt="Gif animado" src="../../../../../assets/img/noticia.gif">
                </ng-template>
                <p>Ya está disponible  la nueva herramienta de convenios donde puedes revisar toda la información en línea <i class="fa fa-envelope" aria-hidden="true"></i></p>
            </p-card>
      
        </div>
        <div class="col-sm-6">
      
        </div>
      </div>



      <div class="row" *ngIf="rolActual.nombre === 'VINCULO'">
        <div class="col-sm-6">
            <p-card header="NUEVO: Convenios" subheader="Consulta convenios en línea" [style]="{width: '360px'}" styleClass="p-card-shadow">
                <ng-template pTemplate="header">
                    <img alt="Gif animado que muestra la ubicacion en el menú" src="../../../../../assets/img/noticia.gif">
                </ng-template>
                <p>Ya está disponible la nueva herramienta de convenios donde puedes revisar toda la información en línea <i class="fa fa-envelope" aria-hidden="true"></i></p>
            </p-card>
      
        </div>
        <div class="col-sm-6">
      
        </div>
      </div>
      

      <div class="row chart-info">
        <div class="col"><span class="title">UNIFY</span></div>
      </div>
    </div>
  </div>
</div> -->