import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiderBarComponent } from './sider-bar/sider-bar.component';
import { NavPerfilComponent } from './nav-perfil/nav-perfil.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { RouterModule } from '@angular/router';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { PrimeraMayusculaPipe } from 'src/app/env/components/pipes/primera-mayuscula.pipe';



@NgModule({
  declarations: [
    NavBarComponent,
    NavPerfilComponent,
    NotificacionesComponent,
    SiderBarComponent,
    PrimeraMayusculaPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports:[
    NavBarComponent,
    SiderBarComponent,
    NavPerfilComponent
    
  ],
})
export class TemplateModule { }
