import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GetAppResponse } from 'src/app/env/app/interfaces/response/get-app-response.interface';
import { AuthService } from 'src/app/env/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { App } from '../../interfaces/app.interface';
import { Notificacion } from '../../interfaces/notificaciones/notificacion.interface';
import { AppRoles, Rol } from '../../interfaces/rol.interface';

@Injectable({
  providedIn: 'root'
})
export class EnvAppService {
  
  

  private baseAPI : string = environment.apiBase
  
  constructor(private http:HttpClient,
            ){};

  getApp(rolToken:string,userToken:string):Observable<GetAppResponse>{
    return this.http.post<GetAppResponse>(`${this.baseAPI}/v1/rol/get_app`,
                                            {'rol':rolToken}
                                            );
  }

  getNotificaciones():Observable<Notificacion[]>{
    return this.http.get<Notificacion[]>(`${this.baseAPI}/v1/notificaciones/`);
  }


  

  
  
}
