
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse } from '../interfaces/response/login-response.interface';
import { AppRoles, Rol } from '../interfaces/rol.interface';
import { User } from '../../../interfaces/user.interface';
import { EnvAppService } from '../home/services/env-app.service';
import { App } from '../interfaces/app.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _sessionStorage:string = 'token';
  private _rolStorage:string = 'accept';
  private _user:User|undefined;
  private _token:string="";
  
  constructor(
              private router:Router,
              private service:EnvAppService
              ) { }

  get sessionStorage():string{
    return this._sessionStorage
  }

  get rolStorage():string{
    return this._rolStorage
  }

  get auth(){
    return this.getAuth()
  }

  //HAY DOS TOKEN, EL TOKEN DE AUTENTIFICACIÓN Y EL TOKEN DEL USUARIO
  //ESTE ES EL TOKEN DE AUTENTIFICACIÓN, ESTE TOKEN CAMBIA EN CADA LOGIN
  //ESTE ATRIBUTO SE USA PARA ENVIARLO EN EL HEADER COMO Authorization
  get authToken(){
    this.getAuth()
    return "Token "+this._token
  }

  //HAY DOS TOKEN, EL TOKEN DE AUTENTIFICACIÓN Y EL TOKEN DEL USUARIO
  //ESTE ES EL TOKEN DE AUTENTIFICACIÓN, ESTE TOKEN CAMBIA EN CADA LOGIN
  getAuthToken(){
    this.getAuth()
    return this._token;
  }

  isAuthenticated():boolean{
    if(this.getAuth()!= undefined){
      return true;
    }
    return false;
  }

  getRoles():Rol[]{

    if(!this.isAuthenticated()){
      return []
    }
    return this._user?.roles!
  }

  //obtiene el usuario logueado
  private getAuth():User|undefined{
    
    var auth = sessionStorage.getItem(this.sessionStorage)
    
    if(!auth){
      return undefined;
    }

    auth = atob(auth+"");
    
    if(auth.trim().length<10){
      return undefined;
    }

    

    const resp : LoginResponse = JSON.parse(auth);
    this._user = resp.user
    this._token = resp.token



    return {...this._user}

  }

  getAuthFullNombre():string{
    if(this._user && this._user.persona){
      return this._user.obj_persona?.nombre_social?this._user.obj_persona?.nombre_social:this._user.obj_persona?.nombre+" "+this._user.obj_persona?.paterno+" "+this._user.obj_persona?.materno;
    }
    return this._user?this._user.username:""
  }

  //elimina la sesion
  logout(){
    sessionStorage.removeItem(this.sessionStorage)
  }

  fullLogout(){
    this.logout();
    this._token="";
    this._user=undefined;
    this.router.navigate(['/login'])
    

  }

  setRolActualStorage(rol:Rol){
    var jsonRol = JSON.stringify(rol);
    localStorage.setItem(this.rolStorage ,  btoa(jsonRol))
  }

  setRolActual(rol:Rol,actualizar=true){

    this.service.getApp(rol.token,this.auth?.token!).subscribe(
      resp=>{
        let apps = resp.apps;
        //console.log(resp.apps)
        let aux_apps:AppRoles[]=[]
        for (let i = 0; i < apps.length; i++) {

          aux_apps.push({
            token:      apps[i].token,
            path:      apps[i].path,
            super_user: apps[i].rol_super_user?true:false,
            orden:      apps[i].rol_orden && apps[i].rol_orden != undefined?Number.parseInt(apps[i].rol_orden+""):null,
            acciones:  apps[i].acciones
          })

          this.setAppHija(apps[i],aux_apps)
          
        }
        let aux:Rol = {...rol}
        aux.apps=aux_apps
        this.setRolActualStorage(aux)

        if(actualizar) window.location.reload();
      }
    )


    //if(actualizar) window.location.reload();
    /*
    this.userService.getRol(rol?.token).subscribe(
      resp=>{
        var jsonRol = JSON.stringify(resp);
        localStorage.setItem(this.rolStorage ,  btoa(jsonRol))
        //se actualiza solo si se requiere
        //este reload se hace para que el template cargue los estilos y eventos para los nuevos botones
        if(actualizar) window.location.reload();
      })
    */

  }

  getRolActual():Rol{

   
    var data = atob(localStorage.getItem(this.rolStorage)+"");

    if(data.trim().length<10){
      return {token: "",
        nombre:      "SIN ROL",
        descripcion: "",
        super_user:  false,
        apps:        []}
    }

    const rol:Rol = JSON.parse(data)
    //console.log(rol)
    return rol
  }

  puedeRealizarAccion(path:string,nombreAccion:string):boolean{

    const rol = this.getRolActual();

    if(!rol)return false

    if (rol?.super_user){
      return true
    }

    var acciones = []

    for (let i = 0; i < rol!.apps.length; i++) {
      if ( rol!.apps[i]?.path?.trim().replace(/[/]/g,"")!=path.trim().replace(/[/]/g,"")  )continue;
      
      //si es super usuario de la app
      if ( rol!.apps[i]?.super_user )return true;
      //console.log(rol!.apps[i])
      if (rol!.apps[i]?.acciones.length==0)return false;

      for (let j = 0; j < rol!.apps[i]?.acciones.length; j++) {
          if(rol!.apps[i]?.acciones[j].nombre.trim().toLowerCase().replace(/á/g,"a").replace(/é/g,"e").replace(/í/g,"i").replace(/ó/g,"o").replace(/ú/g,"u") 
              == nombreAccion.trim().toLowerCase().replace(/á/g,"a").replace(/é/g,"e").replace(/í/g,"i").replace(/ó/g,"o").replace(/ú/g,"u") ){
                return true;
              }
      }
      
    }

    return false
  }





  setAppHija(app:App,aux_apps:AppRoles[]){
    
    if(app.hijos.length==0)return;
    for (let i = 0; i < app.hijos.length; i++) {
      aux_apps.push({
        token:      app.hijos[i].token,
        path:      app.hijos[i].path,
        super_user: app.hijos[i].rol_super_user?true:false,
        orden:      app.hijos[i].rol_orden && app.hijos[i].rol_orden != undefined?Number.parseInt(app.hijos[i].rol_orden+""):null,
        acciones:  app.hijos[i].acciones
      })
      this.setAppHija( app.hijos[i],aux_apps)
      
    }

  }





}
