import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { AuthService } from 'src/app/env/app/services/auth.service';
import { Convenio } from 'src/app/vinculo/convenio/interfaces/convenio.interface';
import { ConvenioService } from 'src/app/vinculo/convenio/services/convenio.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  
})
export class IndexComponent implements OnInit {

  rolActual?: any;
  
  nuevo: boolean = false;
  vencer: boolean = false;
  vencido: boolean = false;
  cerrado: boolean = false;
  renovar: boolean = false;
  
  conveniosNuevos: Convenio[] = [];
  conveniosPorVencer: Convenio[] = [];
  conveniosVencido: Convenio[] = [];
  conveniosCerrados: Convenio[] = [];
  conveniosPorRenovar: Convenio[] = [];
  
  msgConvenioNuevo: string = '';
  msgConvenioVencer: string = '';
  msgConvenioVencido: string = '';
  msgConvenioCerrado: string = '';
  msgConvenioRenovar: string = '';

  constructor( 
              private authService:AuthService,
              private convenioService:ConvenioService
             )  { }

  ngOnInit(): void {
    this.rolActual = this.authService.getRolActual();

    if (this.rolActual.nombre === 'DIVIM'){
      this.alertaNuevo('false');
      this.alertaPorRenovar('false');
      this.alertaPorVencer('false');
      this.alertaVencido('false');
      this.alertaCerrado('false');
    }else if (this.rolActual.nombre === 'ADMISION' || this.rolActual.nombre === 'BECAS_CREDITOS'){
      this.alertaNuevo('true');
      this.alertaPorRenovar('true');
      this.alertaPorVencer('true');
      this.alertaVencido('true');
      this.alertaCerrado('true');
    }
  }


  alertaNuevo(dcto:string){
    this.convenioService.alertaOnDemand('nuevo',dcto).subscribe({
      next: (convenios) => {
        this.conveniosNuevos = convenios;
        if (this.conveniosNuevos.length > 0){
          this.nuevo = true;
          this.msgConvenioNuevo = '<b>Nuevos convenios</b> Hay convenios nuevos en los últimos 30 días. Revisa <a href="/convenio/lista">aqui</a>';
        }
      }
    });
  }
  
  alertaPorRenovar(dcto:string){
    this.convenioService.alertaOnDemand('por_renovar',dcto).subscribe({
      next: (convenios) => {
        this.conveniosPorRenovar = convenios;
        if (this.conveniosPorRenovar.length > 0){
          this.renovar = true;
          this.msgConvenioRenovar = '<b>Renovación de convenio</b> Hay convenios cercanos a la fecha de renovación. Revisa <a href="/convenio/lista">aqui</a>'
        }
      }
    });
  }

  alertaPorVencer(dcto:string){
    this.convenioService.alertaOnDemand('por_vencer',dcto).subscribe({
      next: (convenios) => {
        this.conveniosPorVencer = convenios;
        if (this.conveniosPorVencer.length > 0){
          this.vencer = true;
          this.msgConvenioVencer = '<b>Convenio por vencer</b> Hay convenios cercanos a vencer. Revisa <a href="/convenio/lista">aqui</a>';
        }
      }
    });
  }

  alertaVencido(dcto:string){
    this.convenioService.alertaOnDemand('vencido',dcto).subscribe({
      next: (convenios) => {
        this.conveniosVencido = convenios;
        if (this.conveniosVencido.length > 0){
          this.vencido = true;
          this.msgConvenioVencido = '<b>Convenio vencido</b> Hay convenios recién vencidos. Revisa <a href="/convenio/lista">aqui</a>';
        }
      }
    });
  }

  alertaCerrado(dcto:string){
    this.convenioService.alertaOnDemand('cerrado',dcto).subscribe({
      next: (convenios) => {
        this.conveniosCerrados = convenios;
        if (this.conveniosCerrados.length > 0){
          this.cerrado = true;
          this.msgConvenioCerrado = '<b>Convenio cerrado</b> Hay convenios que fueron cerrados recientemente. Revisa <a href="/convenio/lista">aqui</a>';
        }
      }
    });

  }


}



