import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'primeraMayuscula'
})
export class PrimeraMayusculaPipe implements PipeTransform {

  transform(valor: string): string {

    var texto = valor.toString();
    texto = texto[0].toUpperCase() + texto.substring(1).toLowerCase();

    return texto;
  }

}
