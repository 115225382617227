
<div *ngIf="pages>0" class="table-responsive">
  
    <nav aria-label="...">
        <ul class="pagination">

            <li class="page-item" *ngIf="page>1" (click)="emitir(1)">
                <a class="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
            </li>

            <li class="page-item disabled" *ngIf="page>1" (click)="emitir(page-1)">
                <a class="page-link" >Anterior</a>
            </li>

            <li class="page-item" *ngFor="let item of valoresAnteriores()" (click)="emitir(item)" >
                <a class="page-link">{{item}}</a>
            </li>

            <li class="page-item active">
              <a class="page-link">{{page}}</a>
            </li>

            <li class="page-item" *ngFor="let item of valoresSiguientes()" (click)="emitir(item)"> 
                <a class="page-link">{{item}}</a>
            </li>
            
          <li class="page-item" *ngIf="page<pages" (click)="emitir(page+1)">
            <a class="page-link"  >Siguiente</a>
          </li>

          <li class="page-item" *ngIf="page<pages" (click)="emitir(pages)">
            <a class="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>

        </ul>
      </nav>
</div>
