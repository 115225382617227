


<div class="input-group dropdown">
    <input type="text" 
        style="border-right: 0px solid white;"
        [(ngModel)]="search"
        placeholder="{{placeholder}}" 
        class="form-control " 
        role="button" 
        data-toggle="dropdown" 
        aria-expanded="false"
        (keyup)="keyUpSearch()"
    >
    <div class="input-group-prepend">
        <span class="pt-3 " style="background: #fff;border: 2px solid #eaeaea;border-left: none;" >
            <svg  width="15" height="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9l6 6 6-6" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
    </div>

    <div class="dropdown-menu " style="width: 100%;">
        <a class="dropdown-item" *ngFor="let item of registersFiler " (click)="select(item)">
            <span *ngFor="let label of optionLabels ; let i = index;" > {{ i==0?item[label]:separator+item[label] }}</span>
        </a>
    </div>

</div>
    
  