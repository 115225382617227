export class FuncionesUnify{

    static primeraMayuscula(valor:string):string{
        valor = valor[0].toUpperCase() + valor.substring(1).toLowerCase();
        return valor
    }
 
    static primeraMayusculaPorPalabra(valor:string):string{

        var cadena = '';
        var arregloDeCadenas = valor.split(' ');
        for (var i=0; i < arregloDeCadenas.length; i++) {
            // document.write(arregloDeCadenas[i] + " / ");
            cadena = cadena + ' ' + arregloDeCadenas[i][0].toUpperCase() + arregloDeCadenas[i].substring(1).toLowerCase();
         }
        return cadena
    }    

}