import { Component, EventEmitter, OnInit, Output, ElementRef ,ViewChild, AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { Rol } from 'src/app/env/app/interfaces/rol.interface';
import { AuthService } from 'src/app/env/app/services/auth.service';



@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styles: [`
    a{
      cursor: pointer
    }
  `]
})
export class NavBarComponent implements OnInit {

  @Output() rolSeleccionado:EventEmitter<Rol> = new EventEmitter();

  roles : Rol[] = []
  rol!:Rol;
  cant_notificaciones:number=0;

  constructor(private session:AuthService,
              private router:Router,
              ) { }

  get auth(){
    return this.session?.auth
  }

  ngOnInit(): void {

    this.roles = this.session.getRoles()

    //obtenemos el último rol seleccionado
    const tokenRolActual = this.session.getRolActual();

    //buscamos el último rol seleccionado
    var asignado = false
    for (let i = 0; i < this.roles.length; i++) {
      //si lo encuentra entre los roles del usuario, lo asigna al actual
      if(this.roles[i].token == tokenRolActual?.token){
        this.rol = this.roles[i];
        asignado=true;
        break;
      }
      
    }

    //si no asignó rol por que el usuario ya no lo tiene disponible
    //asignamos el primer rol de sus roles
    if(this.roles.length>0 && !asignado){
      this.rol = this.roles[0];
      this.session.setRolActual(this.rol,false);
      
    }
    //emitimos el rol, para que carge las app
    this.rolSeleccionado.emit(this.rol)

  }

  logout(){
    this.session.logout()
    //necesito que recargue la página por el tema de los js
    window.location.href = "/login";
    //this.router.navigate(['./login'])
  }

  selectRol(rol:Rol){

    this.session.setRolActual(rol);
    this.rol = rol;

    // if(this.router.url=="/"){
    //   window.location.reload();
    // }else{
    //   window.location.href = "/";
    // }

  }

  setSantidad(cantidad:number){
    this.cant_notificaciones=cantidad
  }

}
