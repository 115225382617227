import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Alcance } from '../interfaces/alcance.interface';
import { Ambito } from '../interfaces/ambitoBD.interface';
import { Beneficiario } from '../interfaces/beneficiarioBD.interface';
import { Contacto } from '../interfaces/contacto.interface';
import { Convenio } from '../interfaces/convenio.interface';

import { ConvenioResponse } from '../interfaces/convenio_request.interface';
import { Coordinacion } from '../interfaces/coordinacion.interface';
import { Descuento } from '../interfaces/descuento.interface';
import { Documento } from '../interfaces/documento.interface';
import { Especifico } from '../interfaces/especifico.interface';
import { Procedencia } from '../interfaces/procedencia.interface';

@Injectable({
  providedIn: 'root'
})
export class ConvenioService {

  private cantidadDeRegistros:number=20; //es la cantidad de registros que retornara el servicio

  constructor( private http: HttpClient ) { }


  guardaConvenio(data:any):Observable<any>{
    return this.http.post<any>(`${environment.apiBase}/convenio/v1/convenio/`, data);
  }

  enviaCorreoNuevoConvenio(id_convenio:number){
    let motivo = {
      motivo: 'nuevo'
    }
    return this.http.post(`${environment.apiBase}/convenio/v1/alerta/${id_convenio}`, motivo)
  }

  enviaCorreoConvenioCerrado(id_convenio:number){
    let motivo = {
      motivo: 'cerrado'
    }
    return this.http.post(`${environment.apiBase}/convenio/v1/alerta/${id_convenio}`, motivo)
  }
  
  lista(page:number,order:string):Observable<ConvenioResponse>{
    return this.http.get<ConvenioResponse>(`${environment.apiBase}/convenio/v1/convenio/listar?cant=${this.cantidadDeRegistros}&page=${page}&orden=${order}`);
  }
 
  listaVigentes(parametros:string,page:number,order:string):Observable<ConvenioResponse>{
    return this.http.post<ConvenioResponse>(`${environment.apiBase}/convenio/v1/vigente/descuento`,{parametros:parametros,cant:this.cantidadDeRegistros,page:page,orden:order});
  }

  buscador(parametros:string,page:number):Observable<ConvenioResponse>{
    return this.http.post<ConvenioResponse>(`${environment.apiBase}/convenio/v1/convenio/buscar/`,{parametros:parametros,cant:this.cantidadDeRegistros,page:page});
  }

  alertaOnDemand(indicador:string, dcto:string):Observable<Convenio[]>{
    return this.http.get<Convenio[]>(`${environment.apiBase}/convenio/v1/alerta_ondemand/${indicador}/${dcto}`);
  }

  getEspecificos(convenio:number):Observable<Especifico[]>{
    return this.http.get<Especifico[]>(`${environment.apiBase}/convenio/v1/especificos/${convenio}`);
  }

  getAlcance(convenio:number):Observable<Alcance[]>{
    return this.http.get<Alcance[]>(`${environment.apiBase}/convenio/v1/alcances/${convenio}`);
  }

  getBeneficiarios(convenio:number):Observable<Beneficiario[]>{
    return this.http.get<Beneficiario[]>(`${environment.apiBase}/convenio/v1/beneficiario/${convenio}`);
  }

  getDescuento(convenio:number):Observable<Descuento>{
    return this.http.get<Descuento>(`${environment.apiBase}/convenio/v1/descuento/${convenio}`);
  }

  getAmbito(descuento:number):Observable<Ambito[]>{
    return this.http.get<Ambito[]>(`${environment.apiBase}/convenio/v1/ambito/${descuento}`);
  }

  getCoordinacion(convenio:number):Observable<Coordinacion[]>{
    return this.http.get<Coordinacion[]>(`${environment.apiBase}/convenio/v1/coordinacion/${convenio}`);
  }

  getProcedencia(convenio:number):Observable<Procedencia[]>{
    return this.http.get<Procedencia[]>(`${environment.apiBase}/convenio/v1/procedencia/${convenio}`);
  }  

  getContacto(persona:number):Observable<Contacto[]>{
    return this.http.get<Contacto[]>(`${environment.apiBase}/convenio/v1/contacto/${persona}`);
  }

  getDocumentos(convenio:number):Observable<Documento[]>{
    return this.http.get<Documento[]>(`${environment.apiBase}/convenio/v1/documento/${convenio}`);
  }

  darDeBaja(data:any):Observable<Convenio>{
    return this.http.put<Convenio>(`${environment.apiBase}/convenio/v1/dar_de_baja/`, data);
  }

  conveniosPorInstitucion(institucion_id:number):Observable<Convenio[]>{
    return this.http.get<Convenio[]>(`${environment.apiBase}/convenio/v1/institucion/${institucion_id}/lista`)
  }

}
