import { NgModule } from '@angular/core';

//Modulos de primeNg
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';



@NgModule({
  declarations: [],
  imports: [],
  exports:[
    AccordionModule,
    AutoCompleteModule,
    BadgeModule,
    BreadcrumbModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    ImageModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    KeyFilterModule,
    MenuModule,
    MessagesModule,
    MessageModule,
    MultiSelectModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    StepsModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
    TreeSelectModule,
    
  ]
})
export class PrimengModule { }
