import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
})
export class PaginatorComponent implements OnInit {

  constructor() { }

  @Input() page:number=0;
  @Input() pages:number=0;
  @Input() rago:number=10;
  @Output() emitPage = new EventEmitter<number>();

  private siguientes:number[]=[]
  private anteriores:number[]=[]

  ngOnInit(): void {
    this.page=!this.page?1:this.page
  }

  valoresSiguientes() {
    var next=this.page;
    this.siguientes=[]
    while(next<this.page+this.rago){
      next++;
      if(next>this.pages)break
      this.siguientes.push(next)
    }
    return this.siguientes
    
  }

  valoresAnteriores() {
    var next=this.page;
    this.anteriores=[]
    while(true){
      next--;
      if(next == this.page-this.rago)break
      if(next==0)break
      this.anteriores.push(next)
    }
    return this.anteriores.reverse();
    
  }

  emitir(item:number){
    this.emitPage.emit(item)
  }

}
