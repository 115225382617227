import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/env/app/services/auth.service';
import { User } from '../../../../../interfaces/user.interface';

@Component({
  selector: 'app-nav-perfil',
  templateUrl: './nav-perfil.component.html',
  //styleUrls: ['./nav-perfil.component.css']
})
export class NavPerfilComponent implements OnInit {

  auth!:User|undefined

  constructor(private session:AuthService) { }

  ngOnInit(): void {
    this.auth=this.session.auth;
  }


}
