<nav class="am-right-sidebar">
    <div class="sb-content">
      <div class="user-info">
        
        <svg viewBox="0 0 24 24" width="40" height="40" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
        
        <span class="name mt-5">{{auth?.full_name}}<span class="status"></span></span><span class="position"><!--Art Director--></span></div>

        
      <!--
      <div class="tab-navigation">
        <ul class="nav nav-tabs nav-justified" role="tablist">
          <li role="presentation"><a class="active" href="#tab1" aria-controls="home" role="tab" data-toggle="tab"> <span class="icon s7-smile"></span></a></li>
          <li role="presentation"><a href="#tab2" aria-controls="profile" role="tab" data-toggle="tab"> <span class="icon s7-chat"></span></a></li>
          <li role="presentation"><a href="#tab3" aria-controls="messages" role="tab" data-toggle="tab"> <span class="icon s7-help2"></span></a></li>
          <li role="presentation"><a href="#tab4" aria-controls="settings" role="tab" data-toggle="tab"> <span class="icon s7-ticket"></span></a></li>
        </ul>
      </div>
      -->
      <!--
      <div class="tab-panel">
        <div class="tab-content">
          <div class="tab-pane announcement active" id="tab1" role="tabpanel">
            <div class="announcement-container">
              <div class="announcement-wrapper">
                <div class="am-scroller-announcement">
                  <div class="announcement-content">
                    <h2>Announcement</h2>
                    <ul>
                      <li>
                        <div class="icon"><span class="icon s7-sun"></span></div>
                        <div class="content"><a href="#">Happy Day</a><span>Suspendisse nec leo tortor rhoncus tincidunt. Duis sit amet rutrum elit.</span></div>
                      </li>
                      <li>
                        <div class="icon"><span class="icon s7-gift"></span></div>
                        <div class="content"><a href="#">Congratulations Developers</a><span>Suspendisse nec leo tortor rhoncus tincidunt. Duis sit amet rutrum elit.</span></div>
                      </li>
                      <li>
                        <div class="icon"><span class="icon s7-star"></span></div>
                        <div class="content"><a href="#">High Score</a><span>Suspendisse nec leo tortor rhoncus tincidunt. Duis sit amet rutrum elit.</span></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="search">
                <input type="text" placeholder="Search..." name="q"><span class="s7-search"></span>
              </div>
            </div>
          </div>
          <div class="tab-pane chat" id="tab2" role="tabpanel">
            <div class="chat-contacts">
              <div class="chat-sections">
                <div class="am-scroller-chat">
                  <div class="content">
                    <h2>Recent</h2>
                    <div class="recent">
                      <div class="user"><a href="#"><img src="assets/img/avatar4.jpg">
                          <div class="user-data"><span class="status away"></span><span class="name">Claire Sassu</span><span class="message">Can you share the...</span></div></a></div>
                      <div class="user"><a href="#"><img src="assets/img/avatar7.jpg">
                          <div class="user-data"><span class="status"></span><span class="name">Maggie jackson</span><span class="message">I confirmed the info.</span></div></a></div>
                      <div class="user"><a href="#"><img src="assets/img/avatar3.jpg">
                          <div class="user-data"><span class="status offline"></span><span class="name">Joel King		</span><span class="message">Ready for the meeti...</span></div></a></div>
                    </div>
                    <h2>Contacts</h2>
                    <div class="contact">
                      <div class="user"><a href="#"><img src="assets/img/avatar6.jpg">
                          <div class="user-data2"><span class="status"></span><span class="name">Mike Bolthort</span></div></a></div>
                      <div class="user"><a href="#"><img src="assets/img/avatar7.jpg">
                          <div class="user-data2"><span class="status"></span><span class="name">Maggie jackson</span></div></a></div>
                      <div class="user"><a href="#"><img src="assets/img/avatar8.jpg">
                          <div class="user-data2"><span class="status offline"></span><span class="name">Jhon Voltemar</span></div></a></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="search">
                <input type="text" placeholder="Search..." name="q"><span class="s7-search"></span>
              </div>
            </div>
            <div class="chat-window">
              <div class="title">
                <div class="user"><img src="assets/img/avatar7.jpg">
                  <h2>Maggie jackson</h2><span>Active 1h ago</span>
                </div><span class="icon return s7-angle-left"></span>
              </div>
              <div class="chat-messages">
                <div class="am-scroller-messages">
                  <div class="content">
                    <ul>
                      <li class="friend">
                        <div class="msg">Hello</div>
                      </li>
                      <li class="self">
                        <div class="msg">Hi, how are you?</div>
                      </li>
                      <li class="friend">
                        <div class="msg">Good, I'll need support with my pc</div>
                      </li>
                      <li class="self">
                        <div class="msg">Sure, just tell me what is going on with your computer?</div>
                      </li>
                      <li class="friend">
                        <div class="msg">I don't know it just turns off suddenly</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="chat-input">
                <div class="input-wrapper"><span class="photo s7-camera"></span>
                  <input type="text" placeholder="Message..." name="q" autocomplete="off"><span class="send-msg s7-paper-plane"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane faqs" id="tab3" role="tabpanel">
            <div class="faqs-container">
              <div class="faqs-wrapper">
                <div class="am-scroller-faqs">
                  <div class="faqs-content">
                    <h2>FAQs</h2>
                    <div class="card-group accordion" id="accordion" role="tablist">
                      <div class="card">
                        <div class="card-header" role="tab">
                          <h4 class="card-title"><a data-toggle="collapse" data-parent="#accordion" href="#faq1" aria-expanded="true" aria-controls="collapseOne">
                              <div class="icon"><span class="s7-angle-down"></span></div><span class="title">Under Error 352</span></a></h4>
                        </div>
                        <div class="card-collapse collapse show" id="faq1" aria-labelledby="headingOne" data-parent="#accordion">
                          <div class="card-body">Suspendisse nec leo tortor rhoncus tincidunt. Duis sit amet rutrum elit.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" role="tab">
                          <h4 class="card-title"><a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#faq2" aria-expanded="false" aria-controls="collapseTwo">
                              <div class="icon"><span class="s7-angle-down"></span></div><span class="title">Failure platform</span></a></h4>
                        </div>
                        <div class="card-collapse collapse" id="faq2" aria-labelledby="headingTwo" data-parent="#accordion">
                          <div class="card-body">Suspendisse nec leo tortor rhoncus tincidunt. Duis sit amet rutrum elit.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" role="tab">
                          <h4 class="card-title"><a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#faq3" aria-expanded="false" aria-controls="collapseThree">
                              <div class="icon"><span class="s7-angle-down"></span></div><span class="title">Error 404</span></a></h4>
                        </div>
                        <div class="card-collapse collapse" id="faq3" aria-labelledby="headingThree" data-parent="#accordion">
                          <div class="card-body">Suspendisse nec leo tortor rhoncus tincidunt. Duis sit amet rutrum elit.</div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" role="tab">
                          <h4 class="card-title"><a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#faq4" aria-expanded="false" aria-controls="collapseThree">
                              <div class="icon"><span class="s7-angle-down"></span></div><span class="title">New workstation</span></a></h4>
                        </div>
                        <div class="card-collapse collapse" id="faq4" aria-labelledby="headingFour" data-parent="#accordion">
                          <div class="card-body">Suspendisse nec leo tortor rhoncus tincidunt. Duis sit amet rutrum elit.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="search">
                <input type="text" placeholder="Search..." name="q"><span class="s7-search"></span>
              </div>
            </div>
          </div>
          <div class="tab-pane ticket" id="tab4" role="tabpanel">
            <div class="ticket-container">
              <div class="ticket-wrapper">
                <div class="am-scroller-ticket">
                  <div class="ticket-content">
                    <h2>New Ticket</h2>
                    <form>
                      <div class="form-group send-ticket">
                        <input class="form-control" type="text" placeholder="Title">
                      </div>
                      <div class="form-group send-ticket">
                        <textarea class="form-control" rows="3" placeholder="Write Here..."></textarea>
                      </div>
                      <button class="btn btn-primary btn-lg" type="submit">Create Ticket</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
  </nav>