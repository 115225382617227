import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { App } from '../../interfaces/app.interface';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  appActual!:App;

  constructor(private http:HttpClient,
    private session:AuthService,
    private router:Router,
    ) { }


  private getAppByPath(path:string):Observable<App>{

    return this.http.post<App>(`${environment.apiBase}/v1/app/principal/`
            ,{"path":path}
            ,{ headers: new HttpHeaders().set("Authorization", this.session.authToken) });
  }

  //retorna la app principal, es decir, la app de nivel 1 en la que estamos parados
  getAppPrincipalActual(){
    return this.getAppByPath("/"+this.router.url.split("/")[1]+"/" )
  }


}
