<!--Listado de app principales-->

<div class="am-left-sidebar">
  <div class="content">
    <div class="am-logo"></div>
    <ul class="sidebar-elements" >
        
      <li *ngFor="let app of apps" [class]="getPathPrincipalActual()==app.path?'parent active':'parent'" >
        <a [routerLink]="app.accesible?[app.path]:[]" (click)="selectPadre(app)"><i class="icon {{getIcono(app.path)}}"></i><span>{{getNombre(app.nombre | primeraMayuscula)}}</span></a>
        <ul *ngIf="app.hijos.length>0" >
          <li *ngFor="let appHija of app.hijos" [class]="getPathActual()==appHija.path?'active':''"  (click)="select(app,appHija)" [routerLink]="[appHija.path?appHija.path:'']"><a>{{appHija.nombre | primeraMayuscula}}</a></li>
        </ul>
      </li>
    </ul>
  </div>
</div>

