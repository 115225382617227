<div class="title">Notificaciones<span class="badge">{{notificaciones.length}}</span></div>
<div class="list">
    <div class="am-scroller-notifications ps ps--active-y">
    <div class="content">
        <ul>
        <li class="" *ngFor="let notificacion of notificaciones">
            <a (click)="ir(notificacion)" >
                <div class="logo"><span class="icon s7-pin"></span></div>
                <div class="user-content">
                    <!--<span class="circle"></span>-->
                    <span class="name">{{session.getAuthFullNombre()}}</span><br>
                    <span class="text-content">{{notificacion.notificacion_tipo.descripcion}}</span>
                    <span class="date">{{notificacion.fecha_inicio}}</span>
                </div>
            </a>
        </li>
        </ul>
    </div>
    <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px; height: 222px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 164px;"></div></div></div>
</div>
<!--<div class="footer"> <a href="#">View all notifications</a></div>-->
